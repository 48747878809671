import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

function App() {
  const history = useNavigate();
  const { serialNumber } = useParams();

  const baseUrl="https://saamagree.in/products/";

  useEffect(() => {
    // Data structure to store serial numbers and corresponding product names
    const data = [
      { sNo: 123456, pName: 'Test-product' },
      { sNo: 9807416001017, pName: 'Test-product' },
      // Add more serial numbers and product names as needed
    ];

    // Find the product name based on the serial number
    const product = data.find((item) => item.sNo === parseInt(serialNumber));

    if (product) {
      // replace the current URL with the product name in base URL and redirect
      window.location.replace(`${baseUrl}${product.pName}`);
  }}
  , [history, serialNumber]);

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Saamagree
        </p>
      </header>
    </div>
  );
}

export default App;
