// vreate a react-router-dom component
import { useEffect } from 'react';
import { BrowserRouter, Routes, useNavigate, useParams } from 'react-router-dom';
import logo from './logo.svg';
import { Router, Route } from 'react-router-dom';
import './App.css';
import App from './App';


function AppRouter(){
    return (
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/:serialNumber" element={<App />} />
        </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;